import React, { useContext, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { PATH } from '../helper/config';
import StickyBottom from '../components/utils/sticky-bottom';
import { Helmet } from 'react-helmet';
import '../css/dictionary.css';
import { AppContext } from '../context/context';

const Dictionary: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);

  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    context.setLanguage(
      {
        ja: `${PATH.DICTIONARY}`,
        en: `/en${PATH.DICTIONARY}`
      },
      pageContext.locale
    );
  }, []);

  return (
    <>
      <section className="dictionary util__page">
        <Helmet>
          <title>POST-FAKE DICTIONARY | POST-FAKE</title>
          <meta property="og:title" content={`POST-FAKE DICTIONARY | POST-FAKE`} />
          <meta name="twitter:site" content={`POST-FAKE DICTIONARY | POST-FAKE`} />
          <meta name="twitter:title" content={`POST-FAKE DICTIONARY | POST-FAKE`} />
          <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.DICTIONARY}`} />
        </Helmet>
        <StickyBottom target={'.dummy-elem'}>
          <p className="dictionary__index ff-sans">A-Z INDEX</p>
        </StickyBottom>
        <div className="dictionary__inner">
          <h1 className="dictionary__caption ff-sans-bold">
            POST-FAKE
            <br />
            DICTIONARY
          </h1>
          <ul className="dictionary__list ff-univ">
            {data.category.edges &&
              data.category.edges.map((elem: any, i: number) => {
                let hasItem: boolean = false;
                return (
                  <li className="dictionary__list-item" key={`dictionary__list-item-${elem.node.name}-${i}`}>
                    <p className="dictionary__list-item-caption ff-univ-bold">{elem.node.name}</p>
                    <ul className="dictionary__list-item-list ff-mono">
                      {data.items.edges &&
                        data.items.edges.map((itemElem: any, k: number) => {
                          if (itemElem.node.gqlDictionaryCategories.nodes.length) {
                            if (itemElem.node.gqlDictionaryCategories.nodes[0].name === elem.node.name) {
                              hasItem = true;
                              return (
                                <li className="dictionary__list-item-list-item" key={`dictionary__list-item-list-item-${i}-${k}`}>
                                  <Link to={`${localePath}${PATH.DICTIONARY_DETAIL}${itemElem.node.databaseId}`} className="link__text">
                                    {itemElem.node.gqlDictionariesAcf.title}
                                  </Link>
                                </li>
                              );
                            }
                          }
                        })}

                      {((): any => {
                        if (!hasItem) {
                          return (
                            <li className="dictionary__list-item-list-item" key={`dictionary__list-item-list-item-nothing-${i}`}>
                              <span>-</span>
                            </li>
                          );
                        }
                      })()}
                    </ul>
                  </li>
                );
              })}
          </ul>
        </div>
      </section>
      <div className="dummy-elem"></div>
    </>
  );
};
export default Dictionary;

export const query = graphql`
  query dictionaryListQuery($locale: String!) {
    category: allWpGqlDictionaryCategoryItem {
      edges {
        node {
          name
        }
      }
    }
    items: allWpGqlDictionaryItem(filter: { locale: { locale: { eq: $locale } } }) {
      edges {
        node {
          databaseId
          gqlDictionaryCategories {
            nodes {
              name
            }
          }
          gqlDictionariesAcf {
            title
          }
        }
      }
    }
  }
`;
